<template>
    <v-container>
        <div
            class="d-flex justify-start">
            <v-btn
            @click="()=>{
                $router.go(-1);
            }"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </div>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                </div>
            </v-col>
        </v-row>
        <v-card
            style="border-radius:4px 4px 0px 0px"
            v-if="this.sales!=null"
            color="secondary">
            <div
                class="text-h5 pa-5 white--text">
                Sales
            </div>
        </v-card>
        <v-list-item
            class="px-0"
            v-for="(item,index) in this.sales"
            :key="index">
            <v-container
                class="px-0 py-0">
                <v-card
                    style="border-radius:0px 0px 0px 0px">
                    <div
                        class="pa-4">
                        <div
                            class="d-flex justify-start text-h6 mb-4">
                            {{ item.sales_funnel }}
                        </div>
                        <div
                            class="d-flex justify-start">
                            Report date :
                            <ADateFormat
                            :date="item.created_at"
                            class="mx-2"
                            />
                           
                        </div>
                        <div
                            class="d-flex justify-start">
                            Salesperson :
                            <span class="font-weight-bold mx-2">
                                {{ item.email!=null?item.email:'-' }}
                            </span>
                        </div>
                        <!-- <div
                            class="d-flex justify-start ">
                            Team :
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.salesperson_team!=null?item.salesperson_team:'-'}}
                            </span>
                        </div>
                        <div
                            class="d-flex justify-start ">
                            Name :
                            <span
                                class="font-weight-bold mx-2">
                                {{ item.salesperson_name!=null?item.salesperson_name:'-'}}
                            </span>
                        </div> -->
                    </div>
                </v-card>
            </v-container>
        </v-list-item>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        renewalStatusOption:[
            'Pending',
            'Unreachable',
            'Confirm',
            'Confirm - Pending HRDC',
            'Rejected',
        ],
        searchCompanies:null,
        renewalStatusFiltered:null,
        companiesRenewal : null,
        filteredCompanies:null,
        companiesRenewalHeader :[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'Name',
                value:'company_name',
            },
            {
                text:'Status',
                value:'renewal_status',
            },
            {
                text:'Reported By',
                value:'user_name',
            },
            {
                text:'Reported Date',
                value:'created_at',
            },
            
        ],
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        sales:null,
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if (resp.class === "getCompanySalesByCompany") {
                this.sales = (resp.data.length != 0) ? resp.data : null;
                this.api.isLoading = false;
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.$api.fetch(this.fetchCompanySales());
        },
        fetchCompanySales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/sales/company/detail/" +this.$router.history.current.params.id ;
            return tempApi;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LLL");
        }
    }
}
</script>